<template>
    <div>
        <h2>{{ $t("payment.payment") }}</h2>
        <div class="box">
            <CustomTable
                ref="table_tiers_payment"
                id_table="tiers_payment_supplier"
                :items="payments"
                :busy.sync="table_busy_payments"
                primaryKey="ispayment_id"
                :hide_if_empty="true"
            	:externSlotColumns="extern_slot_columns"
            >
				<template v-slot:custom-slot-cell(check_deposit_formatted)="{ data }">
					<div class="d-flex align-items-center">
						<a href="#" @click.prevent="print({checkdeposit_ids: data.check_deposit.checkdeposit_id.toString()})">{{ data.check_deposit_formatted }}</a>
					</div>
				</template>
				<template v-slot:custom-slot-cell(formatted_invoices)="{ data }">
					<p v-for="invoice in data.invoices" :key="invoice.suppliersocr_id">
                        <a href="" class="" @click.prevent="quickPreview(invoice.suppliersocr_pdf_url)">{{invoice.suppliersocr_num}}</a> ({{ $t('invoice.suppliersocr_balance') }} : {{ invoice.suppliersocr_balance }})
                    </p>
				</template>
			</CustomTable>
        </div>

        <h2>{{ $t("payment.compte_courant") }}</h2>
        <div class="box">
            <p
                class="mb-4"
                v-for="current_account in current_accounts"
                :key="current_account.key"
            >
                {{ $t('payment.current_account_tiers') }} "{{ current_account.key }}" : {{ current_account.amount }}
            </p>

            <CustomTable
                id_table="tiers_payment_current_account_supplier"
                :items="payments_accounts"
                :busy.sync="table_busy_current"
                primaryKey="iscurrentaccount_id"
                :hide_if_empty="true"
            />
        </div>

        <b-modal ref="modalEditPayment" hide-footer>
            <template v-slot:modal-title>
                    {{ $t("payment.edit_payment") }}
            </template>

            <EditPayment ref="editPayment" :payment="payment" :processing.sync="processing" :ready.sync="ready"></EditPayment>

            <div class="col-8 m-auto">
                <b-button v-if="ready" block pill variant="primary" @click.prevent="onSubmitEdit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier") }}</b-button>
            </div>
        </b-modal>

        <b-modal size="xl" ref="modelPreview" hide-footer @hidden="pdf_url = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="pdf_url != null" :src="pdf_url" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>
        
        <ModalConfirm
            ref="confirm_cancel_payment"
            id="confirm_cancel_payment_c"
            :icon="['far', 'check']"
            :icon_accept="['fal', 'level-down']"
            :text_title="$t('modal.invoice.validation_cancel_payment')"
            :text_question="$t('modal.invoice.text_validation_cancel_payment')"
            :text_button_ok="$t('modal.general.ok')"
            :text_button_cancel="$t('modal.general.annuler')"
            :callback_validation="checkCancelPayment"
            :callback_cancel="cancelSwitchCancelPaymentModal"
            :processing="processing_cancel_payment"
        />

    </div>
</template>


<script type="text/javascript">
    import Vue from 'vue'
    import { EventBus } from 'EventBus'
    import CustomTable from "GroomyRoot/components/Table/CustomTable"
    import TableAction from "@/mixins/TableAction.js"
    import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js"
    import Payment from "@/mixins/Payment.js"
    import Config from "@/mixins/Config.js"
    import Tiers from "@/mixins/Tiers.js"

    import AddPayment from "@/components/Payment/AddPayment"

    export default {
        name: "InvoiceSupplierTiersPayment",
        mixins: [Config, TableAction, Invoice, Navigation, Payment, Tiers],
        props: ['tiers_id'],
        data () {
            return {
				tiers_local_id: 0,
                payment: null,
                payments: null,
                payments_accounts: [],
                pdf_url: null,
                table_busy_payments: true,
                table_busy_current: true,
                modal_loaded: false,
                ready: false,
                processing: false,
                current_accounts: [],
                invoice_ids: [],
                invoice_nums: [],
                processing_unpaid_payment: false,
                processing_cancel_payment: false,
                unpaid_params: null,
                cancel_params: null,
				extern_slot_columns: ['check_deposit_formatted', 'formatted_invoices', 'contracts'],
                events_tab: {
                    'tiersPayment::reloadTabs': this.loadPayments,
                    'TableAction::goToCancelPaymentSupplier':  this.confirmCheckCancelPayment,
                    'TableAction::goToEditPayment': this.edit_payment,
                },
                submit_payment: false
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
				this.tiers_local_id = await this.replaceOrSynchronizeId(this.tiers_id)

                this.loadCurrentAccount()
                this.loadPayments()
            },
            async loadPayments() {
                this.table_busy_payments = true

                const payments = await this.getInvoiceSupplierPaymentByTiers(this.tiers_local_id)
                this.payments = await this.formatPaymentsSupplier(payments)

                this.table_busy_payments = false
            },

            async loadCurrentAccount() {
                let tiers = await this.loadTier(this.tiers_local_id)

                // si mon id est encore négatif je ne peux pas appeler l'API
                if(this.tiers_local_id < 0) {
                    this.table_busy_current = false
                    return false
                }

				let current_account_details = await this.getCurrentAccountDetailsSupplier(this.tiers_local_id)

                this.current_accounts = []
				this.payments_accounts = []

                for (let cad in current_account_details)
                {
                    this.current_accounts.push({
                        amount: await this.priceFormat(current_account_details[cad][0].iscurrentaccount_balance / 100, tiers.tiers_currency, true),
                        key: cad
                    })

                    for (let lignes in current_account_details[cad])
                    {
                        this.payments_accounts.push({
                            iscurrentaccount_id: current_account_details[cad][lignes].iscurrentaccount_id,
                            iscurrentaccount_date: current_account_details[cad][lignes].iscurrentaccount_date,
                            iscurrentaccount_inserted_date: current_account_details[cad][lignes].iscurrentaccount_inserted_date,
                            iscurrentaccount_amount: await this.priceFormat(current_account_details[cad][lignes].iscurrentaccount_amount / 100) ,
                            iscurrentaccount_balance: await this.priceFormat(current_account_details[cad][lignes].iscurrentaccount_balance / 100) ,
                            accountingplan_label: cad,
                        })
                    }
                }
                
                this.table_busy_current = false
            },

            edit_payment(params) {
                this.payment = params
                this.payment.ispayment_date = new Date(this.payment.ispayment_date)
                this.$refs['modalEditPayment'].show()
            },

            async onSubmitEdit() {
                const done = await this.$refs['editPayment'].editPayment()
                if(done) {
                    this.$refs["modalEditPayment"].hide()
                    this.unselectAll()
                    this.loadPayments()
                }
            },

            async onSubmit() {
                if(!this.processing)
                {
                    this.processing = true
                    let return_payment = await this.$refs['addPayment'].addPayment()
                    if(return_payment)
                    {
                        this.$refs["modalPayment"].hide()
                        this.$store.commit({ type: 'updateRefreshTabs', view: 'TiersFiche', tab: "coordonneesTiers", val: true })
                        this.$store.commit({ type: 'updateRefreshTabs', view: 'TiersFiche', tab: "Invoice", val: true })
                        this.table_busy = true
                        await this.init_component()
                    }
                    this.processing = false
                }
            },

            confirmCheckCancelPayment(cancel_params) {
                this.cancel_params = cancel_params
                return this.$refs.confirm_cancel_payment.openModal()
            },

            cancelSwitchCancelPaymentModal() {
                this.$refs.confirm_cancel_payment.closeModal()
            },

            async checkCancelPayment(){
                this.processing_cancel_payment = true
                await this.cancelPaymentSupplier(this.cancel_params.map(pay => pay.ispayment_id))
                this.processing_cancel_payment = false
                this.$refs.confirm_cancel_payment.closeModal()

                this.unselectAll()
                await this.loadPayments()
                this.loadCurrentAccount()
            },

			print(params) {
				this.printCheckDeposit(params)
			},

            async quickPreview(pdf_url) {
                this.$refs.modelPreview.show()
                this.pdf_url = pdf_url
            },

            unselectAll() {
        		this.$refs.table_tiers_payment.unselectAll()
        	}
        },
        watch: {
            submit_payment() {
                this.onSubmit()
            }
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            EditPayment: () => import('@/components/InvoiceSupplier/EditPayment'),
            ModalConfirm: () => import('@/components/Modals/ModalConfirm'),

            CustomTable,
            AddPayment
        }
    }
</script>
